import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form/immutable';

import { appReducer as app } from '../features/App';
import { notifications } from '../components/Notifications';
import { memberProfileGeneralInfoReducer as memberProfileGeneralInfo } from '../features/MemberProfile/GeneralInfo';
import { memberProfileFacilityInfoReducer as memberProfileFacilityInfo } from '../features/MemberProfile/FacilityInfo/FacilitiesSection';
import { memberProfileUsageLogReducer as memberProfileUsageLog } from '../features/MemberProfile/UsageLog';
import { accountBankingInfoReducer as accountBankingInfo } from '../features/AccountInfo/BankingInfo';
import { accountInfoReducer as accountInfo } from '../features/AccountInfo';
import { accountReimbursementHistoryReducer as accountReimbursementHistory } from '../features/AccountInfo/ReimbursementHistory';
import { accountInsuranceInfoReducer as accountInsuranceInfo } from '../features/AccountInfo/InsuranceInfo';

import partnersListReducer from '../features/Partners/PartnersList/partnersListReducer';
import memberListReducer from '../features/Members/membersListReducer';
import partnerBankingInfoReducer from '../features/PartnerInfo/BankingInfo/partnerBankingInfoReducer';
import partnerBillingInfoReducer from '../features/PartnerInfo/BillingInfo/partnerBillingInfoReducer';
import managePartnerReducer from '../features/Shared/Partners/managePartnerReducer';
import stateReducer from '../features/States/stateReducer';
import partnerManagerReducer from '../features/Shared/PartnerManager/managePartnerManagerReducer';
import loadingReducer from '../features/Loading/loadingReducer';
import partnerManagersListReducer from '../features/PartnerInfo/PartnerManagers/PartnerManagersList/partnerManagersListReducer';
import programTypeReducer from '../features/ProgramTypes/programTypeReducer';
import programPaymentTypeReducer from '../features/ProgramPaymentTypes/programPaymentTypeReducer';
import partnerBillingTypeReducer from '../features/PaymentTypes/partnerBillingTypeReducer';
import memberPaymentTypeReducer from '../features/PaymentTypes/memberPaymentTypeReducer';
import groupMembershipsReducer from '../features/GroupMemberships/GeneralInfo/groupMembershipsReducer';
import employeeManagementReducer from '../features/GroupMemberships/EmployeeManagement/employeeManagementReducer';
import facilityLocatorReducer from '../features/MemberArea/Dashboard/FacilityLocator/facilityLocatorReducer';
import facilityLocatorMapReducer from '../features/MemberArea/Dashboard/FacilityLocator/FacilityLocatorMap/facilityLocatorMapReducer';
import facilityReducer from '../features/Facilities/facilityReducer';
import legalContractReducer from '../features/MemberProfile/FacilityInfo/LegalContract/legalContractReducer';
import partnerOwnerAreaReducer from '../features/PartnerOwnerArea/partnerOwnerAreaReducer';
import partnerManagerAreaReducer from '../features/PartnerManagerArea/partnerManagerAreaReducer';
import memberAreaReducer from '../features/MemberArea/memberAreaReducer';
import partnerPaymentsSectionReducer from '../features/WellnessProgramPayments/PartnerPaymentsSection/partnerPaymentsSectionReducer';
import periodsReducer from '../features/Shared/Periods/periodsReducer';
import partnerPaymentWithdrawalSectionReducer from '../features/WellnessProgramPayments/PartnerPaymentWithdrawalSection/partnerPaymentWithdrawalSectionReducer';
import facilityPaymentDepositSectionReducer from '../features/WellnessProgramPayments/FacilityPaymentDepositSection/facilityPaymentDepositSectionReducer';
import visitLogTabReducer from '../features/GroupMemberships/VisitLog/visitLogTabReducer';
import partnerGroupMemberhipsPaymentsReducer from '../features/GroupMemberships/PartnerGroupMembershipsPayments/partnerGroupMemberhipsPaymentsReducer';
import partnerPaymentsGenerationSectionReducer from '../features/WellnessProgramPayments/PartnerPaymentsGenerationSection/partnerPaymentsGenerationSectionReducer';
import facilityPaymentsGenerationSectionReducer from '../features/WellnessProgramPayments/FacilityPaymentsGenerationSection/facilityPaymentsGenerationSectionReducer';
import facilityPaymentsSectionReducer from '../features/WellnessProgramPayments/FacilityPaymentsSection/facilityPaymentsSectionReducer';
import memberPaymentsReducer from '../features/WellnessProgramPayments/MemberPaymentsTab/memberPaymentsReducer';
import wellnessProgramPaymentsReducer from '../features/WellnessProgramPayments/wellnessProgramPaymentsReducer';
import voucherProgramsListReducer from '../features/VoucherPrograms/VoucherProgramsList/voucherProgramsListReducer';
import voucherProgramReducer from '../features/VoucherPrograms/VoucherProgram/voucherProgramReducer';
import recipientsListReducer from '../features/VoucherPrograms/RecipientsList/recipientsListReducer';
import clubLocatorSectionReducer from '../components/ClubLocatorSection/clubLocatorSectionReducer';
import loginReducer from '../components/Login/loginReducer';
import forgotPasswordReducer from '../components/ForgotPassword/forgotPasswordReducer';
import contactUsReducer from '../features/App/GuestArea/ContactUs/contactUsReducer';
import facilitySearchBoxReducer from '../features/Members/FacilitySearchBox/facilitySearchBoxReducer';
import memberTransferReducer from '../features/Members/MemberTransfer/memberTransferReducer';
import uploadRecipientsResultsReducer from '../features/Shared/UploadRecipientsResults/uploadRecipientsResultsReducer';
import paymentTypeReducer from '../features/PaymentTypes/paymentTypeReducer';
import agreementFormReducer from '../features/MemberProfile/FacilityInfo/LegalContract/LegalContractSteppedFormForms/agreementFormReducer';
import legalContractFormReducer from '../features/MemberProfile/FacilityInfo/LegalContract/LegalContractForm/legalContractFormReducer';
import plansFilterReducer from '../components/PlansFilter/plansFilterReducer';
import paymentDetailsFormReducer from '../features/MemberProfile/FacilityInfo/LegalContract/LegalContractSteppedFormForms/paymentDetailsFormReducer';

const rootReducer = history => combineReducers({
    router: connectRouter(history),
    form,
    app,
    notifications,
    memberProfileGeneralInfo,
    memberProfileFacilityInfo,
    memberProfileUsageLog,
    accountBankingInfo,
    accountInfo,
    accountReimbursementHistory,
    accountInsuranceInfo,
    partnerBankingInfoReducer,
    partnerBillingInfoReducer,
    managePartnerReducer,
    partnersListReducer,
    memberListReducer,
    stateReducer,
    partnerManagerReducer,
    loadingReducer,
    partnerManagersListReducer,
    programTypeReducer,
    programPaymentTypeReducer,
    partnerBillingTypeReducer,
    memberPaymentTypeReducer,
    groupMembershipsReducer,
    employeeManagementReducer,
    facilityLocatorReducer,
    facilityLocatorMapReducer,
    facilityReducer,
    partnerOwnerAreaReducer,
    partnerManagerAreaReducer,
    memberAreaReducer,
    partnerPaymentsSectionReducer,
    periodsReducer,
    partnerPaymentWithdrawalSectionReducer,
    facilityPaymentDepositSectionReducer,
    visitLogTabReducer,
    partnerGroupMemberhipsPaymentsReducer,
    partnerPaymentsGenerationSectionReducer,
    facilityPaymentsGenerationSectionReducer,
    facilityPaymentsSectionReducer,
    wellnessProgramPaymentsReducer,
    voucherProgramsListReducer,
    voucherProgramReducer,
    recipientsListReducer,
    legalContractReducer,
    clubLocatorSectionReducer,
    loginReducer,
    forgotPasswordReducer,
    contactUsReducer,
    facilitySearchBoxReducer,
    memberTransferReducer,
    uploadRecipientsResultsReducer,
    memberPaymentsReducer,
    paymentTypeReducer,
    agreementFormReducer,
    legalContractFormReducer,
    plansFilterReducer,
    paymentDetailsFormReducer
});

export default rootReducer;
