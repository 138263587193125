import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../../../../../components/controls/FormGroup';
import { Field, reduxForm, initialize } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../../../utilities/form';
import { connect } from 'react-redux';
import { MuiInputText } from '../../../../../components/controls/MuiInputText';
import MuiButton from '../../../../../components/controls/MuiButton';
import MuiInputCheckbox from '../../../../../components/controls/MuiInputCheckbox';
import agreementFormResources from './agreementFormResources';
import * as validationRules from './agreementFormValidations';
import selectors from './agreementFormSelectors';
import { mergeWith, is } from 'immutable';
import { getEquipmentAndFacilitiesPolicies } from './agreementFormActions';
import Spinner from '../../../../../components/Spinner/Spinner';
import legalContractResources from '../legalContractResources';

function AgreementForm({
    isLoading,
    pristine,
    submitting,
    handleSubmit,
    onPrevStep,
    initialize,
    initialValues,
    documentAgreementVersion,
    formValues,
    form,
    onFormSubmit,
    getEquipmentAndFacilitiesPolicies,
    equipmentAndFacilitiesPolicies,
    formParams }) {
    useEffect(() => {
        if (formValues && !is(formValues, initialValues)) {
            initialValues = mergeWith((oldValue, newValue, key) => {
                return key === 'isMemberAgreed' ? oldValue : newValue;
            }, initialValues, formValues);
        }
        initialize(form, initialValues);
    }, [initialValues]);
    useEffect(() => {
        getEquipmentAndFacilitiesPolicies(documentAgreementVersion, formParams.programPaymentType, formParams.facilityAFNumber);
    }, [documentAgreementVersion, formParams.programPaymentType, formParams.facilityAFNumber]);
    return isLoading
        ? <Spinner />
        : <FormGroup onSubmit={handleSubmit(onFormSubmit)}>
            <div className="grid__row">
                <div className="col-md-12">
                    <div className="equipment-and-facility-policies__container" dangerouslySetInnerHTML={{ __html: equipmentAndFacilitiesPolicies }} />
                    <Field
                        id="is-agreed"
                        label={agreementFormResources.labelIsMemberAgreed}
                        name="isMemberAgreed"
                        component={MuiInputCheckbox}
                        validate={[
                            validationRules.isMemberAgreedRequired
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-4">
                    <Field
                        label={agreementFormResources.labelFirstName}
                        name="firstName"
                        component={MuiInputText}
                        validate={[
                            validationRules.firstNameRequired,
                            validationRules.firstNameMaxLength
                        ]}
                    />
                </div>
                <div className="col-md-4">
                    <Field
                        label={agreementFormResources.labelLastName}
                        name="lastName"
                        component={MuiInputText}
                        validate={[
                            validationRules.lastNameRequired,
                            validationRules.lastNameMaxLength
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-4">
                    <Field
                        label={agreementFormResources.labelContactNumber}
                        name="contactNumber"
                        component={MuiInputText}
                        validate={[
                            validationRules.phoneNumberRequired,
                            validationRules.phoneNumberRegex,
                            validationRules.phoneNumberMaxLength
                        ]}
                    />
                </div>
                <div className="col-md-4">
                    <Field
                        label={agreementFormResources.labelEmailAddress}
                        name="email"
                        component={MuiInputText}
                        validate={[
                            validationRules.emailAddressRequired,
                            validationRules.emailAddressRegex,
                            validationRules.emailAddressMaxLength
                        ]}
                    />
                </div>
                <div className="col-md-4">
                    <Field
                        label={agreementFormResources.labelDate}
                        name="date"
                        component={MuiInputText}
                        disabled
                    />
                </div>
            </div>
            <div className="grid__row">
                <MuiButton
                    className="btn_new btn_new--green"
                    disabled={pristine || submitting}
                    type="submit"
                >
                    {agreementFormResources.labelButtonSave}
                </MuiButton>
                <MuiButton className="btn_new btn_new--white"
                    onClick={onPrevStep}
                >
                    {legalContractResources.linkButtonPrevious}
                </MuiButton>
            </div>
        </FormGroup>;
}

AgreementForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    documentAgreementVersion: PropTypes.number.isRequired,
    formValues: PropTypes.object,
    initialize: PropTypes.func,
    form: PropTypes.string.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    getEquipmentAndFacilitiesPolicies: PropTypes.func.isRequired,
    equipmentAndFacilitiesPolicies: PropTypes.string,
    formParams: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    initialize,
    getEquipmentAndFacilitiesPolicies
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    ...defaultFormProperties,
    enableReinitialize: false,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
}
)(connectStateToProps(AgreementForm));