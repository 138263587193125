const legalContractResources = {
    legalCotractTitleEnroll: 'Enroll',
    legalCotractTitleEnrolled: 'Enrolled',
    legalCotractTitleFormat: '{0} in {1}',
    legalContractTitleKeyfobStep: 'Key Fob',
    legalContractTitleWithdrawalDetailsStep: 'Withdrawal Details',
    legalContractTitleAgreementStep: 'Agreement',
    legalContractTitlePaymentDetailsStep: 'Payment Details',
    formNameLegalContract: 'legalcontract',
    linkButtonNextText: 'Next',
    linkButtonPrevious: 'Back',
};
export default legalContractResources;