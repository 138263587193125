import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import FormGroup from '../../../../../components/controls/FormGroup';
import MonthlyDuesPaymentDetailsInfoBox from './MonthlyDuesPaymentDetailsInfoBox';
import { defaultFormProperties } from '../../../../../utilities/form';
import MuiButton from '../../../../../components/controls/MuiButton';
import legalContractResources from '../legalContractResources';
import Spinner from '../../../../../components/Spinner/Spinner';
import { getGroupMembership } from '../../../../GroupMemberships/GeneralInfo/groupMembershipsActions';
import { getCalculatedDues } from './paymentDetailsFormActions';
import selectors from './gmspPaymentDetailsFormSelectors';

function GMSPPaymentDetailsForm({ submitting, onNextStep, onPrevStep, handleSubmit, formParams, isLoading, getGroupMembership, getCalculatedDues, monthlyDues, todaysPayment }) {
    useEffect(() => {
        getGroupMembership(formParams.partnerID);
        getCalculatedDues(formParams.partnerID, formParams.agreementDate);
    }, [formParams.partnerID]);
    return (isLoading
        ? <Spinner />
        : <FormGroup onSubmit={handleSubmit(onNextStep)} name="payment-details-form">
            <div className="grid__row">
                <div className="col-md-6">
                    <MonthlyDuesPaymentDetailsInfoBox monthlyDues={monthlyDues} todaysPayment={todaysPayment} />
                </div>
            </div>
            <div className="grid__row">
                <MuiButton className="btn_new btn_new--green"
                    disabled={submitting}
                    type="submit"
                >
                    {legalContractResources.linkButtonNextText}
                </MuiButton>
                <MuiButton className="btn_new btn_new--white"
                    onClick={onPrevStep}
                >
                    {legalContractResources.linkButtonPrevious}
                </MuiButton>
            </div>
        </FormGroup>
    );
}

GMSPPaymentDetailsForm.propTypes = {
    submitting: PropTypes.bool.isRequired,
    onNextStep: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formParams: PropTypes.object.isRequired,
    getGroupMembership: PropTypes.func.isRequired,
    getCalculatedDues: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    monthlyDues: PropTypes.string,
    todaysPayment: PropTypes.string,
};

const mapDispatchToProps = {
    getGroupMembership,
    getCalculatedDues
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    ...defaultFormProperties,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connectStateToProps(GMSPPaymentDetailsForm));