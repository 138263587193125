import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import { MuiRadioGroup } from '../../../components/controls/MuiRadioGroup';
import MuiButton from '../../../components/controls/MuiButton';
import FormGroup from '../../../components/controls/FormGroup';
import BankingInfoResources, { BankAccountTypeOptions } from './bankingInfoResources';
import WarningBox from '../../../components/WarningBox/WarningBox';
import {
    routingNumberRequired,
    routingNumberNumeric,
    routingNumberLength,
    routingNumberSpecialCombination,
    accountNumberRequired,
    accountNumberNumericWithWildcard,
    accountNumberLength,
    accountTypeRequired
} from './validate';
import './_banking-info.scss';
import { connect } from 'react-redux';
import getIsEmpty from './bankingInfoStepFormSelectors';

function BankingInfoStepForm({ handleSubmit, submitting, isEmpty, onPrevStep, onNextStep }) {
    return (
        <FormGroup onSubmit={handleSubmit(onNextStep)}>
            <div className="grid__row">
                <div className="col-md-12">
                    <span className="bank-info-text">{BankingInfoResources.infoBankingInfo}</span>
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-12">
                    <WarningBox description={BankingInfoResources.warningBoxDescription} />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-6">
                    <Field
                        id={BankingInfoResources.routingNumber}
                        name={BankingInfoResources.routingNumber}
                        label={BankingInfoResources.routingNumberLabel}
                        component={MuiInputText}
                        validate={[
                            routingNumberRequired,
                            routingNumberNumeric,
                            routingNumberLength,
                            routingNumberSpecialCombination
                        ]}
                    />
                </div>
                <div className="col-md-6">
                    <Field
                        id={BankingInfoResources.accountNumber}
                        name={BankingInfoResources.accountNumber}
                        label={BankingInfoResources.accountNumberLabel}
                        component={MuiInputText}
                        validate={[
                            accountNumberRequired,
                            accountNumberNumericWithWildcard,
                            accountNumberLength
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-12">
                    <Field
                        component={MuiRadioGroup}
                        label={BankingInfoResources.accountTypeLabel}
                        name={BankingInfoResources.bankAccountType}
                        options={BankAccountTypeOptions}
                        validate={[
                            accountTypeRequired
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <MuiButton className="btn_new btn_new--green"
                    disabled={isEmpty || submitting}
                    type="submit"
                >
                    {BankingInfoResources.linkButtonNext}
                </MuiButton>
                {onPrevStep &&
                    <MuiButton className="btn_new btn_new--white"
                        onClick={onPrevStep}
                    >
                        {BankingInfoResources.linkButtonPrevious}
                    </MuiButton>
                }
            </div>
        </FormGroup >
    );
}

BankingInfoStepForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    isEmpty: PropTypes.bool,
    onPrevStep: PropTypes.func,
    onNextStep: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
    const { form } = props;
    return {
        isEmpty: getIsEmpty(state, form),
    };
};

export default reduxForm({
    ...defaultFormProperties,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps)(BankingInfoStepForm));
