const agreementFormResources = {
    labelIsMemberAgreed: 'I have read this release and understand the contents, and I knowingly and voluntarily sign this release. I agree to abide by all posted signage and warnings',
    labelButtonSave: 'Submit',
    customValidationMessagelabelIsMemberAgreed: 'You must check to agree.',
    labelFirstName: 'First Name',
    labelLastName: 'Last Name',
    labelContactNumber: 'Contact Number',
    labelEmailAddress: 'Email Address',
    labelDate: 'Date',
    labelDownloadEquipmentAndFacilitiesPolicies: 'Download Equipment and Facilities Policies'
};
export default agreementFormResources;