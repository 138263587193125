import * as loadingActions from '../../../../Loading/loadingActions';
import * as paymentDetailsFormService from './paymentDetailsFormService';
import * as types from './paymentDetailsFormTypes';

export const setCalculatedDues = (calculatedDues) => {
    return { type: types.SET_CALCULATED_DUES, calculatedDues };
};

export function getCalculatedDues(partnerId, startingDate) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_CALCULATED_DUES));
        return paymentDetailsFormService
            .getCalculatedDues(partnerId, startingDate)
            .then((response) => {
                dispatch(setCalculatedDues(response.data));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.GET_CALCULATED_DUES)));
    };
};