import React, { useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import moment from 'moment';
import { SLASHED_US_DATE_FORMAT } from '../../../constants/formats';
import Spinner from '../../../components/Spinner/Spinner';
import selectors from './memberPaymentsSelectors';
import NewTable from '../../../components/NewInfoBox/NewTable';
import resources from './memberPaymentsResources';
import TableFiltersPanel from '../../../components/NewInfoBox/TableFiltersPanel';
import MemberPaymentsFiltersForm from './MemberPaymentsFiltersForm';
import actions from './memberPaymentsActions';
import { getPeriodsUntilCurrent } from '../../Shared/Periods/periodsActions';
import CurrencyCell from '../../../components/NewInfoBox/CurrencyCell';
import BulletCell from '../../../components/NewInfoBox/BulletCell';
import { paymentStatusesColorsMap, paymentStatusesNamesMap } from '../../../enums/paymentStatuses';
import { memberBillingTypesNamesMap } from '../../../enums/memberBillingTypes';
import { isTabletSize } from '../../../utilities/windowSize';
import styles from './_member-payments.module.scss';
import MuiButton from '../../../components/controls/MuiButton';
import ConnectedConfirmPopup from '../../../components/Popups/ConfirmAlert/ConnectedConfirmPopup';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import PaymentConfirmationForm from '../PaymentConfirmationForm/PaymentConfirmationForm';

function MemberPaymentsTab({
    payments,
    totalPaymentsAmount,
    memberPaymentsCount,
    isLoading,
    programPaymentTypes,
    initialFilterValues,
    memberPaymentTypes,
    getPartnersListByProgramPaymentType,
    programTypeID,
    partnerID,
    periodID,
    partners,
    getPeriodsUntilCurrent,
    periods,
    defaultPeriodID,
    setPeriodID,
    getPayments,
    isViewButtonDisabled,
    popupMessage,
    popupSubmitText,
    checkMemberPaymentsProcessed,
    sendMemberPayments,
    popupData }) {
    const tableRef = useRef();
    const isTablet = isTabletSize();
    const columns = useMemo(
        () => [
            {
                Header: resources.columnNameEmployeeName,
                accessor: resources.accessorEmployeeName,
                sticky: isTablet ? 'left' : null,
                left: 0,
                sortType: 'caseInsensitiveAlphaNumeric'
            },
            {
                Header: resources.columnNameFacilityId,
                accessor: resources.accessorFacilityId,
                sortType: 'caseInsensitiveAlphaNumeric'
            },
            {
                Header: resources.columnNameAmountToBeWithdrawn,
                accessor: resources.accessorAmountToBeWithdrawn,
                Cell: CurrencyCell
            },
            {
                Header: resources.columnNameMemberPaymentType,
                accessor: resources.accessorMemberPaymentType,
                Cell: cell => memberBillingTypesNamesMap[cell.value]
            },
            {
                Header: resources.columnNameBillingInfo,
                accessor: resources.accessorBillingInfo
            },
            {
                Header: resources.columnNameStatus,
                accessor: resources.accessorStatus,
                Cell: cell => BulletCell({ ...cell, value: paymentStatusesNamesMap[cell.value] }),
                getProps: row => ({
                    theme: paymentStatusesColorsMap[row.original.paymentStatusID]
                }),
            },
            {
                Header: resources.columnNameDate,
                accessor: resources.accessorDate,
                Cell: cell => moment(cell.value).format(SLASHED_US_DATE_FORMAT)
            }
        ],
        [resources, isTablet]
    );
    useEffect(() => {
        if (defaultPeriodID) {
            setPeriodID(defaultPeriodID);
        }
    }, [defaultPeriodID]);
    useEffect(() => {
        getPeriodsUntilCurrent();
    }, []);
    useEffect(() => {
        if (programTypeID) {
            getPartnersListByProgramPaymentType(programTypeID);
        }
    }, [programTypeID]);
    const handleSubmit = (filters) => {
        filters = filters.toJS();
        getPayments(filters.partnerID, filters.periodID);
    };
    const employeeNameAccessor = (item) => {
        return item.memberFullName;
    };
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const handlePINSubmit = (confirmationData) => {
        sendMemberPayments(partnerID, periodID, confirmationData.get('pin')).then(() => getPayments(partnerID, periodID));
        setIsPopupOpened(false);
    };
    const handleSend = () => {
        checkMemberPaymentsProcessed(partnerID, periodID).then(() => {
            setIsPopupOpened(true);
        });
    };
    const tableButton = <MuiButton className="btn_new btn_new--green" onClick={handleSend} noPadding>
        {resources.buttonSend}
    </MuiButton>;
    const memberPaymentWithdrawalConfirmationForm = 'memberPaymentWithdrawalConfirmationForm';
    return <div className="member-payments">
        {isLoading ? <Spinner /> : null}
        {
            <ConnectedConfirmPopup
                showPopup={isPopupOpened}
                title={wellnessProgramPaymentResources.titleConfirmACHUpload}
                body={
                    <PaymentConfirmationForm
                        message={popupMessage}
                        form={memberPaymentWithdrawalConfirmationForm}
                        initialValues={popupData}
                        onSubmit={handlePINSubmit}
                    />
                }
                onConfirm={(dispatch) => {
                    dispatch(submit(memberPaymentWithdrawalConfirmationForm));
                }}
                onCancel={() => setIsPopupOpened(false)}
                labelOk={popupSubmitText}
                labelCancel={wellnessProgramPaymentResources.buttonCancel}
            />
        }
        <TableFiltersPanel>
            <MemberPaymentsFiltersForm
                programPaymentTypes={programPaymentTypes}
                paymentTypes={memberPaymentTypes}
                partners={partners}
                periods={periods}
                initialValues={initialFilterValues}
                viewButtonDisabled={isViewButtonDisabled}
                onSubmit={handleSubmit}
            />
        </TableFiltersPanel>
        <div className={styles.member_payments_total}>{resources.headerTotalDuesAmount}: {totalPaymentsAmount}</div>
        <NewTable
            columns={columns}
            initialData={payments}
            getItemId={employeeNameAccessor}
            initialSortBy={{
                id: resources.accessorEmployeeName,
                desc: false
            }}
            totalCount={memberPaymentsCount}
            ref={tableRef}
            manualSortBy={false}
            tableButton={tableButton}
        />
    </div>;
}

MemberPaymentsTab.propTypes = {
    initialFilterValues: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    memberPaymentTypes: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    payments: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalPaymentsAmount: PropTypes.string.isRequired,
    memberPaymentsCount: PropTypes.number.isRequired,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired,
    programTypeID: PropTypes.number,
    partnerID: PropTypes.number,
    periodID: PropTypes.number,
    getPeriodsUntilCurrent: PropTypes.func.isRequired,
    defaultPeriodID: PropTypes.number,
    setPeriodID: PropTypes.func.isRequired,
    getPayments: PropTypes.func.isRequired,
    isViewButtonDisabled: PropTypes.bool.isRequired,
    popupMessage: PropTypes.string,
    popupSubmitText: PropTypes.string,
    checkMemberPaymentsProcessed: PropTypes.func.isRequired,
    sendMemberPayments: PropTypes.func.isRequired,
    popupData: PropTypes.object.isRequired
};

const mapDispatchToProps = {
    ...actions,
    getPeriodsUntilCurrent
};

export default connect(selectors, mapDispatchToProps)(MemberPaymentsTab);
