
import React from 'react';
import PropTypes from 'prop-types';
import styles from './_currency-cell.module.scss';
import currencyFormatter from '../../utilities/formatters/currencyFormatter';

function CurrencyCell({ value }) {
    return <div className={styles.currency_cell}>{currencyFormatter.formatToUSDollar(value)}</div>;
}

CurrencyCell.propTypes = {
    value: PropTypes.number,
};

export default CurrencyCell;
