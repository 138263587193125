import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from '../../../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../../../components/InfoBox/InfoBoxRow';
import paymentDetailsFormResources from './paymentDetailsFormResources';
import styles from './_paymentDetailsForm.module.scss';

function MonthlyDuesPaymentDetailsInfoBox({ monthlyDues, todaysPayment }) {
    return <InfoBox bordered>
        <InfoBoxRow label={paymentDetailsFormResources.labelMonthlyDues} content={monthlyDues} bottomborder contentposition="right" />
        <span className={styles.description_text}>{paymentDetailsFormResources.captionPaymentOnFirstOfEveryMonth}</span>
        <InfoBoxRow label={paymentDetailsFormResources.labelTodaysPayment} content={todaysPayment} bottomborder contentposition="right" />
        <InfoBoxRow label={paymentDetailsFormResources.labelTodaysTotalPayment} content={todaysPayment} contentposition="right" bold />
    </InfoBox>;
}

MonthlyDuesPaymentDetailsInfoBox.propTypes = {
    monthlyDues: PropTypes.string,
    todaysPayment: PropTypes.string,
};

export default MonthlyDuesPaymentDetailsInfoBox;