import * as types from './paymentDetailsFormTypes';
import initialState from './paymentDetailsFormInitialState';

export default function paymentDetailsFormReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_CALCULATED_DUES:
            return state.set('calculatedDues', action.calculatedDues);
        default:
            return state;
    }
}
