import { createSelector } from 'reselect';
import { GET_GROUP_MEMBERSHIP } from '../../../../GroupMemberships/GeneralInfo/groupMembershipsTypes';
import { GET_CALCULATED_DUES } from './paymentDetailsFormTypes';
import currencyFormatter from '../../../../../utilities/formatters/currencyFormatter';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_GROUP_MEMBERSHIP || i === GET_CALCULATED_DUES)
        .size;
    return size > 0;
};

const getGroupMembership = state => state.groupMembershipsReducer.get('groupMembership');
const getMonthlyDues = createSelector([getGroupMembership], groupMembership => currencyFormatter.formatToUSDollar(groupMembership.get('memberDuesAmount')));
const getTodaysPayment = state => currencyFormatter.formatToUSDollar(state.paymentDetailsFormReducer.getIn(['calculatedDues', 'duesAmount']));
const getMonthlyReimbursements = createSelector([getGroupMembership], groupMembership => currencyFormatter.formatToUSDollar(groupMembership.get('memberReimbursementAmount')));
const getRequiredVisits = createSelector([getGroupMembership], groupMembership => groupMembership.get('numberOfVisitsToReimburse'));

export default createSelector([getIsLoading, getMonthlyDues, getTodaysPayment, getMonthlyReimbursements, getRequiredVisits], (isLoading, monthlyDues, todaysPayment, monthlyReimbursements, requiredVisits) => ({ isLoading, monthlyDues, todaysPayment, monthlyReimbursements, requiredVisits }));