import * as loadingActions from '../../../Loading/loadingActions';
import * as legalContractService from './legalContractService';
import * as types from './legalContractTypes';

const getMemberLegalContractInfoSuccessAction = (signatureMemberInfo) => {
    return { type: types.GET_SIGNATURE_MEMBER_INFO_SUCCESS, signatureMemberInfo };
};

export function getMemberLegalContractInfo(memberIdGuid) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_SIGNATURE_MEMBER_INFO));
        legalContractService.getMemberLegalContractInfo(memberIdGuid)
            .then((signatureMemberInfo) => {
                dispatch(getMemberLegalContractInfoSuccessAction(signatureMemberInfo));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_SIGNATURE_MEMBER_INFO));
            });
    };
}