import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_info-box.scss';

const InfoBox = (props) => {
    const { title, bordered = false, className } = props;
    return (
        <div className={classNames(['info-box', { 'info-box--bordered': bordered }, { [className]: className }])}>
            {title && <h6 className="sub info-box__title">{title}</h6>}
            <div className="info-box__content">
                {props.children}
            </div>
        </div>
    );
};

InfoBox.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    bordered: PropTypes.bool,
    className: PropTypes.string
};

export default InfoBox;
