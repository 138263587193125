import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../../../components/controls/FormGroup';
import { defaultFormProperties } from '../../../../../utilities/form';
import * as validationRules from './keyfobFormValidations';
import { MuiInputText } from '../../../../../components/controls/MuiInputText';
import MuiButton from '../../../../../components/controls/MuiButton';
import keyfobFormResources from './keyfobFormResources';
import styles from './_keyfobform.module.scss';
import legalContractResources from '../legalContractResources';
import { connect } from 'react-redux';
import selectors from './keyfobFormSelectors';

function KeyfobForm({ isEmpty, submitting, onNextStep, handleSubmit }) {
    return (
        <FormGroup onSubmit={handleSubmit(onNextStep)}>
            <div className="grid__row">
                <div className="col-md-12">
                    <span className={styles.info_text}>{keyfobFormResources.infoKeyfob}</span>
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-4">
                    <Field
                        label={keyfobFormResources.labelKeyfob}
                        name={keyfobFormResources.facilityMemberID}
                        component={MuiInputText}
                        validate={[
                            validationRules.keyfobRequired,
                            validationRules.keyfobMinMaxLength,
                            validationRules.keyfobValidRadixNumber,
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <MuiButton className="btn_new btn_new--green"
                    disabled={isEmpty || submitting}
                    type="submit"
                >
                    {legalContractResources.linkButtonNextText}
                </MuiButton>
            </div>
        </FormGroup>
    );
};

KeyfobForm.propTypes = {
    isEmpty: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    onNextStep: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    ...defaultFormProperties,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(selectors)(KeyfobForm));